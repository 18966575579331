import { Directive, DirectiveBinding } from "vue"

import { Editor } from "@tiptap/core"
import { isPlainObject } from "lodash-es"

import { IS_CYPRESS_ENV } from "~/utils"

interface CypressAttributes {
  sel: string
  editor?: Editor
}

const setCypressAttributes = (
  el: HTMLElement,
  binding: DirectiveBinding<CypressAttributes | string>,
) => {
  if (!IS_CYPRESS_ENV) return

  if (isPlainObject(binding.value)) {
    for (const [ key, value ] of Object.entries(binding.value)) {
      el.setAttribute(`data-cy-${key}`, value as string)
    }
  }

  if (typeof binding.value === "string") {
    el.setAttribute("data-cy-sel", binding.value)
  }
}

export const directiveCy: Directive = {
  created (el, binding) {
    setCypressAttributes(el, binding)
  },
  mounted (el, binding) {
    setCypressAttributes(el, binding)
  },
  updated (el, binding) {
    setCypressAttributes(el, binding)
  },
}
