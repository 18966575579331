/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from "axios"
window.axios = axios

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest"
window.axios.defaults.headers.common["Accept"] = "application/json"

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from "laravel-echo"

import Pusher from "pusher-js"
window.Pusher = Pusher


function constructPusherAuthEndpoint () {
  const url = new URL(window.location.href)
  const token = url.searchParams.get("token")

  if (token !== null) {
    // document-user urls have to pass authentication information to the auth interface
    return "/broadcasting/auth?token=" + token
  } else {
    // web urls, have a session and don't need additional authentication information
    return "/broadcasting/auth"
  }
}


// VITE_ env variables are not available when deploying via vapor (vite build is executed without proper environment)
// instead we check origin and initiate pusher according to the domain!
if (window.location.origin === "https://app.fynk.com") {
  // we are serving production, load pusher with prod key
  window.Echo = new Echo({
    broadcaster: "pusher",
    key: "0813eb94fca75ccc36e1",
    cluster: "eu",
    forceTLS: true,
    authEndpoint: constructPusherAuthEndpoint(),
  })
} else if (window.location.origin === "https://staging.fynk.com") {
  // we are serving staging, load pusher with prod key
  window.Echo = new Echo({
    broadcaster: "pusher",
    key: "54daf153b65e39cbdde4",
    cluster: "eu",
    forceTLS: true,
    authEndpoint: constructPusherAuthEndpoint(),
  })
} else {
  window.Echo = new Echo({
    broadcaster: "pusher",
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    cluster: "eu",
    wsHost: import.meta.env.VITE_PUSHER_HOST ?? `ws-${import.meta.env.VITE_PUSHER_APP_CLUSTER}.pusher.com`,
    wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
    wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
    forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? "https") === "https",
    enabledTransports: [ "ws", "wss" ],
    authEndpoint: constructPusherAuthEndpoint(),
  })
}
