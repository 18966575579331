// Make vite aware of favicons, fonts and external js snippets referenced in <head> and css
import.meta.glob([
  "../favicons/**",
  "../fonts/**",
  "../integrations/**",
])

import resetStore from "./stores/resetStore"

import { createInertiaApp, router } from "@inertiajs/vue3"
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers"
import { DefineComponent, createApp, h } from "vue"
import { createPinia } from "pinia"
import { defineRule, configure } from "vee-validate"
import { required, email, confirmed, min, regex, url } from "@vee-validate/rules"
import VueTippy from "vue-tippy"

import { ZiggyVue } from "ziggy-js"
// import { ZiggyVue } from "../../vendor/tightenco/ziggy/dist/vue.m"

import "../css/app.css"
import "../css/dancingscript.css"
import "../css/kalam.css"
import "../css/inter.css"

import "./bootstrap"

import { directiveCy } from "./directives"

import i18nInstance from "~/utils/i18n"

import * as Sentry from "@sentry/vue"

const appName = window.document.getElementsByTagName("title")[0]?.innerText || "fynk"


createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: (name) => (resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob("./Pages/**/*.vue")) as Promise<DefineComponent>),
  setup ({ el, App, props, plugin }) {
    const pinia = createPinia()
    pinia.use(resetStore)

    const VueApp = createApp({ render: () => h(App, props) })

    VueApp
      .use(plugin)
      .use(ZiggyVue)
      .use(pinia)
      .use(i18nInstance)
      .use(
        VueTippy,
        {
          arrow: true,
          directive: "tippy", // => v-tippy
          component: "Tippy", // => <Tippy/>
          componentSingleton: "TippySingleton", // => <TippySingleton />,
        },
      )
      .directive("cy", directiveCy)

    if (window.location.origin === "https://app.fynk.com" || window.location.origin === "https://staging.fynk.com") {
      Sentry.init({
        app: VueApp,
        dsn: "https://5744ac350dd2104d588e1fcf100af284@o4507409537695744.ingest.de.sentry.io/4507409547198544",
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,

        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,

        integrations: [ new Sentry.Replay() ],

        environment: window.location.origin === "https://app.fynk.com" ? "production" : "staging",
      })

      VueApp.mixin(Sentry.createTracingMixins(({ trackComponents: true } as TracingOptions)))
      Sentry.attachErrorHandler(VueApp, ({ logErrors: true } as VueOptions))
    }

    VueApp.mixin({
      methods: {
        asset: window.Vapor.asset,
      },
    })

    VueApp.mount(el)

    return VueApp
  },
  progress: {
    color: "#4338ca",
  },
})

// initialize vapor
import Vapor from "laravel-vapor"
import { TracingOptions, VueOptions } from "@sentry/vue/types/types"

declare const window: any
window.Vapor = Vapor
window.Vapor.withBaseAssetUrl(import.meta.env.VITE_VAPOR_ASSET_URL)

// global validation rules
defineRule("required", required)
defineRule("email", email)
defineRule("confirmed", confirmed)
defineRule("min", min)
defineRule("regex", regex)
defineRule("url", url)

const { t } = i18nInstance.global

configure({
  generateMessage: (context) => {
    let message = t("validation.invalid", { fieldName: t("formFields." + context.field) })
    if (context.rule?.name === "required") message = t("validation.required", { fieldName: t("formFields." + context.field) })
    if (context.rule?.name === "email") message = t("validation.email", { fieldName: t("formFields." + context.field) })
    if (context.rule?.name === "confirmed") message = t("validation.confirmed", { fieldName: t("formFields." + context.field) })
    if (context.rule?.name === "min") message = t("validation.min", { fieldName: t("formFields." + context.field) })
    return message
  },
})

// Prevent back button from showing outdated pages
window.addEventListener("popstate", (event: PopStateEvent) => {
  // no special handling if external token param exists
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  if (!!urlParams.get("token")) {
    return
  }

  event.stopImmediatePropagation()

  // On mere hash changes, do not force router reload
  if (event.state === null) {
    event.preventDefault()
    return false
  }

  router.reload({
    replace: true,
    onSuccess: (page) => {
      (router as any).setPage(page)
    },
    onError: () => window.location.href = event.state.url,
  })
})

/** CONTAINER QUERY POLYFILL FOR OLDER BROWSER VERSIONS */
// Support Test
const supportsContainerQueries = "container" in document.documentElement.style

// Conditional Import
if (!supportsContainerQueries) {
  import("container-query-polyfill")
}
